import aatral from "./aatral.png";
import airshed from "./airshed.png";
import allngo from "./allngo.jpeg";
import ankur from "./ankur.jpeg";
import atom360 from "./atom360.jpeg";
import atya from "./atya.png";
import avanti from "./avanti.png";
import balajee from "./balajee.jpeg";
import berry from "./berry.png";
import blockchain from "./blockchain.jpeg";
import bug from "./bug.png";
import bupp from "./bupp.jpeg";
import bunkojunko from "./bunkojunko.png";
import callprep from "./callprep.png";
import cei from "./cei.jpeg";
import cerbotech from "./cerbotech.jpeg";
import community from "./community.png";
import connector from "./connectfor.jpeg";
import cry from "./cry.png";
import cwe from "./cwe.png";
import deepvision from "./deepvision.jpeg";
import digitalhorizon from "./digitalhorizon.png";
import duosis from "./duosis.png";
import eco from "./eco.png";
import edible from "./edible.png";
import eshway from "./eshway.jpeg";
import gold from "./gold.jpeg";
import growth from "./growth.png";
import hongirana from "./hongirana.jpeg";
import imerit from "./imerit.png";
import intellify from "./intellify.jpeg";
import ivolunteer from "./ivolunteer.png";
import junoon from "./junoon.png";
import karosambahv from "./karosambahv.png";
import kisanwindow from "./kisanwindow.png";
import leapforword from "./leapforword.jpeg";
import lifetrust from "./lifetrust.png";
import makemy from "./makemy.jpeg";
import maker from "./maker.jpeg";
import medicaps from "./medicaps.png";
import mfarm from "./mfarm.png";
import michigan from "./michigan.svg";
import mindspirits from "./mindspirits.png";
import miraheze from "./miraheze.png";
import nexus from "./nexus.png";
import nusocio from "./nusocio.jpeg";
import pdcr from "./pdcr.png";
import pikk from "./pikk.jpeg";
import pranyas from "./pranyas.png";
import pride from "./pride.png";
import prithak from "./prithak.png";
import purnata from "./purnata.png";
import raatai from "./raatai.jpeg";
import raudic from "./raudic.jpeg";
import revy from "./revy.jpeg";
import rina from "./riina.jpeg";
import sensed from "./sensed.jpeg";
import sevasahayog from "./sevasahayog.jpeg";
import shandar from "./shandar.jpeg";
import sheel from "./sheel.webp";
import shikshasharth from "./shikshasharth.png";
import sos from "./sos.jpeg";
import switchon from "./switchon.jpeg";
import taangh from "./taangh.png";
import thinkzone from "./thinkzone.jpeg";
import tickelforart from "./tickelforart.png";
import toptrove from "./toptrove.jpeg";
import trucup from "./trucup.jpeg";
import tummas from "./tummas.png";
import visions from "./visions.png";
import vrkshetra from "./vrkshetra.png";
import waste from "./waste.jpeg";
import wne3 from "./waste.jpeg";
import worldv from "./worldv.jpeg";
import yourdigital from "./yourdigital.jpeg";


const company_list = [
  {
    company_id: 1,
    company_name: "Aatral ESP",
    job_title: "Chemical Engineer, Software, and Electrical Engineer",
    domain: "Miscellaneous",
    job_description: "Chemical Engineer, Software, and Electrical Engineer",
    mode: "Hybrid",
    stipend: "NA",
    number_of_fellows: 4,
    website_link: "www.aatral-esp.com",
    city: "Chennai",
    image: aatral,
  },
  {
    company_id: 33,
    company_name: "Eshway_business",
    job_title: "Business Developer",
    domain: "Miscellaneous",
    job_description: "Drive growth by identifying new business opportunities and building client relationships.",
    mode: "Hybrid",
    stipend: "up to 25k INR / month",
    number_of_fellows: "1-2",
    website_link: "www.eshway.com",
    city: "Mumbai",
    image: eshway,
  },
  {
    company_id: 2,
    company_name: "Airshed Planning Professionals Pvt. Ltd.",
    job_title: "Air Quality Intern",
    domain: "Analyst",
    job_description: "Source Apportionment and Emission Inventory studies for various cities. Site visit for Data Collection of air polluting sources. Research on our latest project with Department of Telecommunication - Digital Twin",
    mode: "Online",
    stipend: "10k / month",
    number_of_fellows: "2-4",
    website_link: "https://www.airshed.in/index.php",
    city: "Kanpur (Only work from Office)",
    image: airshed,
  },
  {
    company_id: 3,
    company_name: "All India NGO Association",
    job_title: "Social Intern",
    domain: "NGO",
    job_description: "Social Service",
    mode: "Online",
    stipend: "0 / month",
    number_of_fellows: "Not Specified",
    website_link: "Not Specified",
    city: "Indore",
    image: allngo,
  },
  {
    company_id: 4,
    company_name: "Ankur Pratishthan",
    job_title: "Content Developer",
    domain: "Media",
    job_description: "Geography Module Development of Maharashtra State Board which will be ready to use material for Tutor & Volunteers and the content must include: Simplified theory, Objective of the chapter, Including images, Developing Fun & Learn Activities, Questions in Worksheets which covers all the contents of the chapter.",
    mode: "Hybrid",
    stipend: "6000 / month",
    number_of_fellows: 5,
    website_link: "www.ankurpratishthan.org",
    city: "Mumbai",
    image: ankur,
  },
  {
    company_id: 5,
    company_name: "Atom360",
    job_title: "React native App Development Intern",
    domain: "Web Dev",
    job_description: "Develop and maintain React native mobile applications for healthcare solutions. Collaborate with the development team to implement new features and functionalities. Ensure the performance, quality, and responsiveness of applications.",
    mode: "Online",
    stipend: "₹7000/month",
    number_of_fellows: "1-2",
    website_link: "https://www.atom360.ai/",
    city: "NA",
    image: atom360,
  },
  {
    company_id: 6,
    company_name: "Atya Education_tutors",
    job_title: "Tutors",
    domain: "Miscellaneous",
    job_description: "Assist in teaching, lesson planning, and student support.",
    mode: "Online",
    stipend: "2000-15000 / month",
    number_of_fellows: 5,
    website_link: "https://www.atyaeducation.com/",
    city: "NA",
    image: atya,
  },




  {
    company_id: 11,
    company_name: "Avanti Fellows",
    job_title: "Product Management",
    domain: "Product Management",
    job_description: "Assist in the creation of Avanti's career counselling tools by updating, analyzing, and improving the relevant databases. This role is ideal for 1st or 2nd-year students who want to learn basic data analysis and build a product that will help thousands of students make informed decisions about their careers.",
    mode: "Online",
    stipend: "10000",
    number_of_fellows: 1,
    website_link: "Avanti Fellows",
    city: "NA",
    image: avanti,
  },
  {
    company_id: 12,
    company_name: "Balajee Sewa Sansthan_Finance",
    job_title: "Sustainable Income",
    domain: "Finance",
    job_description: "To Make a model of 30-60 farmers by adopting Eco Rural Tourism with Integrated Farming System for Livelihood Enhancement and double the Income of small and marginal farmers. Selection of farmers and designing the low-cost model of Integrated Farming System for small farmers.",
    mode: "Hybrid",
    stipend: "Only Field travel facility available / month",
    number_of_fellows: 2,
    website_link: "www.balajee.org",
    city: "Dehradun",
    image: balajee,
    },

  {
    company_id: 14,
    company_name: "Berry Clean",
    job_title: "Social Media Intern",
    domain: "Media",
    job_description: "Help develop and implement social media strategies. Boost brand awareness on platforms like Instagram, Facebook, and others. Maintain brand consistency in all content. Engage with followers and support campaign promotions. Aid in increasing follower count and analyzing social media performance. Contribute to monthly newsletters and content updates. Suggest improvements for ongoing social media campaigns. Perform other related tasks as needed.",
    mode: "Online",
    stipend: "Rs2500-3000 / month",
    number_of_fellows: 1,
    website_link: "https://www.berryclean.in/",
    city: "NA",
    image: berry,
  },
  {
    company_id: 15,
    company_name: "Blockchained India_Web Dev",
    job_title: "Blockchain and Web3",
    domain: "Web Dev",
    job_description: "Founder's Staff - 1 Social Media - 1 Technical Writer - 1 Business Development - 1 Backend Development - 1",
    mode: "Offline",
    stipend: "10,000 - 25,000 / month",
    number_of_fellows: 1,
    website_link: "https://www.blockchainedindia.com/",
    city: "Delhi",
    image: blockchain,
  },


  {
    company_id: 18,
    company_name: "Bug Coders",
    job_title: "Freelancer Web and App Development",
    domain: "Web Dev",
    job_description: "Web and App Development",
    mode: "Online",
    stipend: "20k / month",
    number_of_fellows: 1,
    website_link: "Hiring for making website",
    city: "Kanpur",
    image: bug,
  },
  {
    company_id: 19,
    company_name: "BUNKO JUNKO",
    job_title: "Marketing Manager Intern",
    domain: "Marketing",
    job_description: "Corporate Outreach: Identify and reach out to potential corporate clients to pitch our ecofriendly gifting solutions. Sales Pitch Development: Develop and deliver compelling sales pitches and presentations to corporate clients. Market Research: Conduct research to identify new business opportunities and market trends in the corporate gifting sector. Client Relationship Management: Build and maintain strong relationships with corporate clients to ensure satisfaction and repeat business. Collaborative Projects: Work closely with the marketing team to develop promotional materials and strategies to attract new clients. Reporting: Provide regular updates and reports on outreach activities, client feedback, and sales progress.",
    mode: "Offline",
    stipend: "10,000 / month",
    number_of_fellows: 2,
    website_link: "www.bunkojunko.com",
    city: "Delhi",
    image: bunkojunko,

  },
  {
    company_id: 20,
    company_name: "CallPrep",
    job_title: "Sales, Product, Design, Content Writing",
    domain: "Marketing",
    job_description: "Sales: Conduct prospecting and lead generation activities to identify potential clients and opportunities. Sales: Assist in sales efforts by creating advanced Excel spreadsheets, utilizing APIs for data analysis, and conducting business research. Product: Perform prompt engineering to work on and improve our AI-generated insights. Design: Utilize Canva and other creative tools to develop engaging marketing materials and content for our target audience. Content Writing: Collaborate with the team to develop and implement content marketing strategies to drive customer engagement and retention. English (Written): Utilize your strong English proficiency in written communication to craft compelling messaging and creative writing for various platforms. Work closely with the founder to support daily operations and special projects as needed. Gain valuable hands-on experience and insight into the inner workings of a successful startup while contributing your skills and ideas to help drive growth and success.",
    mode: "Hybrid",
    stipend: "10000 / month",
    number_of_fellows: 1,
    website_link: "CallPrep",
    city: "Gurgaon",
    image: callprep,
  },
  {
    company_id: 21,
    company_name: "Confederation of Empowerment Initiatives",
    job_title: "Project Development Intern",
    domain: "Marketing",
    job_description: "Sales and Marketing.",
    mode: "Online",
    stipend: "0-5k / month",
    number_of_fellows: 100,
    website_link: "https://ceiempowers.org/",
    city: "NA",
    image: cei,
  },
  {
    company_id: 70,
    company_name: "REVY Environmental Solutions Pvt. Ltd._design",
    job_title: "Customer Insights Intern",
    domain: "Analyst",
    job_description: "Design and implement surveys to gather consumer insights on REVY's offerings. Analyze qualitative and quantitative data to understand customer behavior and preferences. Collaborate with product development teams to incorporate consumer feedback. Present insights and recommendations to guide marketing and product strategies.",
    mode: "Hybrid",
    stipend: "Pro-bono basis / month. Certificate provided upon completion.",
    number_of_fellows: 1,
    website_link: "https://www.revy.co.in/",
    city: "Vadodara",
    image: revy
  },
  {
    company_id: 22,
    company_name: "CerboTech Education Private Limited",
    job_title: "Empowering Minds for Social Impact",
    domain: "NGO",
    job_description: "1. Assist in the development and implementation of mental wellness programs. 2. Conduct research on neurofeedback systems and related technologies. 3. Collaborate with the team to design and optimize user experiences for wellness applications. 4. Analyze data and provide insights to improve product performance. 5. Support marketing efforts through content creation and social media management. 6. Participate in brainstorming sessions and contribute innovative ideas for product enhancement. 7. Engage in outreach activities to promote mental health awareness. 8. Provide administrative support and assist in organizing events and workshops. 9. Contribute to the overall growth and success of CerboTech's initiatives in the mental wellness space.",
    mode: "Hybrid",
    stipend: "5000 / month",
    number_of_fellows: 3,
    website_link: "https://smartkids.cerbotech.in/",
    city: "Ghatkopar, Mumbai",
    image: cerbotech,
  },
  {
    company_id: 23,
    company_name: "Community Action for Rural Development",
    job_title: "Liaison Officer",
    domain: "NGO",
    job_description: "Responsible for contacting and submitting CSR proposals and participating in further discussions until the proposal is sanctioned.",
    mode: "Offline",
    stipend: "Actual travel expenses for visiting the companies in Mumbai / month",
    number_of_fellows: 2,
    website_link: "https://www.cardtn.org.in",
    city: "Mumbai",
    image: community,
  },
  {
    company_id: 24,
    company_name: "ConnectFor",
    job_title: "Multiple Positions",
    domain: "NGO",
    job_description: "Matching and connecting volunteers to NGOs based on their volunteering/internship requirements and interest/skillset.",
    mode: "Online",
    stipend: "NA",
    number_of_fellows: 50,
    website_link: "www.connectfor.org",
    city: "All the metropolitan cities of India",
    image: connector,
  },
  {
    company_id: 25,
    company_name: "CRY- Child Rights and You",
    job_title: "Designing a volunteer-focused campaign strategy for festive celebrations",
    domain: "Consult",
    job_description: "The fellow will research, strategize, and design an execution deck for festive celebrations. Requires skills in smart research, strategic thinking, quick learning, professional reports, and presentations,: 4 weeks",
    mode: "Online",
    stipend: "3000 / month",
    number_of_fellows: 2,
    website_link: "www.cry.org",
    city: "NA",
    image: cry,
  },
  {
    company_id: 26,
    company_name: "Catalyst for Women Entrepreneurship (CWE)",
    job_title: "Project Manager",
    domain: "Product Management",
    job_description: "Conduct interviews with Role Models (owners of successful business enterprises) associated with CWE. Accurately capture information in designated forms. Write success stories from interviews to be published individually. Create an impact study report. Excellent spoken and written communication skills. Proficient in note-taking. Effective presentation skills. Strong interpersonal skills. Strong storytelling ability. Research and analytical skills. Attention to detail. Proficiency in using social media platforms. Proficiency with GSuite, Canva, Word, and Excel.",
    mode: "Hybrid",
    stipend: "Honorary position",
    number_of_fellows: 2,
    website_link: "https://cwe.org.in/",
    city: "Bengaluru",
    image: cwe,
  },
  {
    company_id: 27,
    company_name: "DeepVisionTech.AI",
    job_title: "Marketing Executive - Intern",
    domain: "Marketing",
    job_description: "Develop and execute marketing campaigns to drive brand awareness, customer acquisition, and retention including SEO. Collaborate with the marketing and other teams (deaf & hearing people) to create compelling content for various channels, including social media, email, website, and print. Conduct market research to identify trends, competitor activities, and customer preferences. Participate and represent the company and solutions in events, workshops, etc. as required. Reach out to NGOs & other organizations who help or employ deaf, speech & hearing impaired people, and introduce our solutions & create brand awareness. Analyze campaign performance and metrics to optimize marketing strategies and tactics. Manage and maintain relationships with external partners, agencies, and vendors. Monitor industry trends and identify opportunities for innovation and growth. Stay updated with the latest marketing tools, technologies, and trends to enhance our marketing efforts. Assist in developing marketing plans and budgets to support business objectives. Provide regular reports and updates on marketing activities, performance, and ROI to management.",
    mode: "Hybrid",
    stipend: "Between 15K and 25K based on performance",
    number_of_fellows: 2,
    website_link: "DeepVisionTech.AI",
    city: "Not Specified",
    image: deepvision,
  },
  {
    company_id: 28,
    company_name: "Distinct Horizon Pvt Ltd",
    job_title: "Associate Consultant CSR",
    domain: "Consult",
    job_description: "Support in Designing CSR project, execution and monitoring.",
    mode: "Online",
    stipend: "Probably no. May be considered after 1 month if contribution is significant and skill sets are aligned / month",
    number_of_fellows: 4,
    website_link: "www.distincthorizon.net",
    city: "NA",
    image: digitalhorizon,

  },
  {
    company_id: 29,
    company_name: "Duosis Bio-Innovation Pvt Ltd",
    job_title: "Renewable Energy / Green Cosmetics Intern/Sustainability Intern",
    domain: "Research Project",
    job_description: "Renewable Energy Intern will conduct research on renewable energy sources, focusing on bioethanol production. Green Cosmetics Intern will support R&D efforts in sustainable beauty solutions using Invogel.",
    mode: "Online",
    stipend: "Not Specified",
    number_of_fellows: 1,
    website_link: "Not Specified",
    city: "Not Specified",
    image: duosis,
  },
  {
    company_id: 30,
    company_name: "Eco Society India",
    job_title: "Conservation Biology Intern",
    domain: "NGO",
    job_description: "Taking part in conservation activities, Undertake environmental awareness sessions.",
    mode: "Offline",
    stipend: "10,000 / month",
    number_of_fellows: 2,
    website_link: "www.ecosocietyindia.com",
    city: "NA",
    image: eco,
  },
  {
    company_id: 16,
    company_name: "Blockchained India_Finance",
    job_title: "Blockchain and Web3",
    domain: "Finance",
    job_description: "Founder's Staff - 1 Social Media - 1 Technical Writer - 1 Business Development - 1 Backend Development - 1",
    mode: "Offline",
    stipend: "10,000 - 25,000 / month",
    number_of_fellows: 1,
    website_link: "https://www.blockchainedindia.com/",
    city: "Delhi",
    image: blockchain,
  },
  {
    company_id: 31,
    company_name: "Edible Routes",
    job_title: "finance, marketing, and operations",
    domain: "Finance",
    job_description: "Tasks will be spread across strategy, finance, marketing, and operations.",
    mode: "Hybrid",
    stipend: "5000 / month",
    website_link: "www.edibleroutes.com",
    city: "Delhi",
    image: edible,
  },

  {
    company_id: 7,
    company_name: "Atya Education_Web Dev",
    job_title: "Web Developers",
    domain: "Web Dev",
    job_description: "Aid in developing and maintaining websites.",
    mode: "Online",
    stipend: "2000-15000 / month",
    number_of_fellows: 5,
    website_link: "https://www.atyaeducation.com/",
    city: "NA",
    image: atya,
  },

  {
    company_id: 13,
    company_name: "Balajee Sewa Sansthan_NGO",
    job_title: "Develop an Integrated Farming system",
    domain: "NGO",
    job_description: "To provide need based services to the economically & socially disadvantaged groups by creating opportunities for self employment & economic development. In the field of health care and environment, to strive and help build a healthy society with emphasis on environmental cleanliness and personal hygiene.",
    mode: "Hybrid",
    stipend: "Only Field Travel facility will be available / month",
    number_of_fellows: 2,
    website_link: "www.balajee.org",
    city: "Dehradun",
    image: balajee,
  },
  {
    company_id: 34,
    company_name: "Eshway_Marketing",
    job_title: "Marketing",
    domain: "Marketing",
    job_description: "Develop and execute marketing strategies to enhance brand presence and attract clients.",
    mode: "Hybrid",
    stipend: "up to 25k INR / month",
    number_of_fellows: "1-2",
    website_link: "www.eshway.com",
    city: "Mumbai",
    image: eshway,
  },

  {
    company_id: 36,
    company_name: "Gold Coin Seva Trust",
    job_title: "Social Intern",
    domain: "NGO",
    job_description: "Social Intern",
    mode: "Offline",
    stipend: "0 / month",
    number_of_fellows: 20,
    website_link: "Not Specified",
    city: "Indore",
    image: gold,

  },

  {
    company_id: 37,
    company_name: "Growth Valley Community",
    job_title: "Educational Program Consultant Intern (IIT)",
    domain: "Consult",
    job_description: "We are inviting enthusiastic and driven Interns from IIT to join our team as Educational Program Consultants. In this role, you will be instrumental in guiding prospective students through GVC’s diverse educational offerings, helping them find the right fit while immersing yourself in an environment rich with international exposure and professional development opportunities. Conduct detailed consultations with prospective students to understand their goals and guide them towards appropriate GVC programs. Articulate the benefits and features of GVC’s programs, highlighting our international connections, including workshops and mentorship with Ivy League alumni. Assess the fit between the students’ aspirations and our program offerings, ensuring a mutual beneficial educational journey. Collaborate with our team to enhance the overall student experience and program effectiveness.",
    mode: "Offline",
    stipend: "Rs 25000 / month",
    number_of_fellows: 15,
    website_link: "https://www.growthvalleycommunity.com",
    city: "Gurugram",
    image: growth,
  },
  {
    company_id: 8,
    company_name: "Atya Education",
    job_title: "Web Designers",
    domain: "Design",
    job_description: "Assist in designing website layouts and usability.",
    mode: "Online",
    stipend: "2000-15000 / month",
    number_of_fellows: 5,
    website_link: "https://www.atyaeducation.com/",
    city: "NA",
    image: atya,
  },
  {
    company_id: 38,
    company_name: "Hongirana Culture and Social Society (R) Haliyal",
    job_title: "Puppet Artist",
    domain: "Miscellaneous",
    job_description: "Puppet Show",
    mode: "Offline",
    stipend: "15,000 / month",
    number_of_fellows: 12,
    website_link: "www.hongiranapuppets.org",
    city: "Haliyal, Uttara Kannada",
    image: hongirana,
  },

  {
    company_id: 39,
    company_name: "iMerit",
    job_title: "Interns",
    domain: "AI/ML",
    job_description: "ML model development, GenaI applications, web stack development.",
    mode: "Hybrid",
    stipend: "Rs. 35,000 / month",
    number_of_fellows: 3,
    website_link: "www.imerit.net",
    city: "Bangalore",
    image: imerit,
  },

  {
    company_id: 75,
    company_name: "Shandar services pvt Ltd_Media",
    job_title: "Social Media",
    domain: "Media",
    job_description: "Create, schedule, and monitor content.",
    mode: "Hybrid",
    stipend: "2500 to 5000/month",
    number_of_fellows: 10,
    website_link: "www.shandarservices.com",
    city: "Patna Bihar",
    image: shandar
},
  {
    company_id: 40,
    company_name: "Intellify Edventures Private Ltd",
    job_title: "Founder's Office",
    domain: "AI/ML",
    job_description: "A startup founded by IIT Delhi Alumni to help individuals and companies leverage Generative AI.",
    mode: "Online",
    stipend: "5000 / month",
    number_of_fellows: 3,
    website_link: "Www.wasteconnect.org",
    city: "Not Specified",
    image: intellify,
  },
  {
    company_id: 41,
    company_name: "iVolunteer",
    job_title: "Education",
    domain: "Miscellaneous",
    job_description: "Fellow will take up the role of an assistant teacher at community learning centres situated inside of a slum community and assist in teaching students from Grade 1 to 7. This opportunity will serve the dual purpose of enabling the transfer of skills and knowledge from volunteers to the young children and also enable volunteers to learn the challenging positions from which different parts of Mumbai come from, and be able to be compassionate and enable the service users to strengthen their academic foundation in order to effectively continue with their studies. Children hailing from the slum communities often find difficulty in continuing with their academics due to lack of resources, and support. Through this opportunity, volunteers will develop a strong foundation for students and provide them with the required assistance, guidance, and support to be able to have a sustainable academic journey.",
    mode: "Online",
    stipend: "0 / month",
    number_of_fellows: 20,
    website_link: "https://www.ivolunteer.in",
    city: "Mumbai - Mankhur, Govandi, Thane, Kalwa",
    image: ivolunteer,
  },
  {
    company_id: 42,
    company_name: "JUNOON FOUNDATION KANDIVALI",
    job_title: "Diverse Roles",
    domain: "Marketing",
    job_description: "Various roles including marketing, social media, content writing, event planning, HR, research, operations, academics, and website building.",
    mode: "Hybrid",
    stipend: "LOR, certificates, additional bonuses based on performance / month",
    number_of_fellows: 13,
    website_link: "www.junoon.org.in",
    city: "Mumbai",
    image: junoon,
  },
  {
    company_id: 43,
    company_name: "Karo Sambhav",
    job_title: "E-Waste Management, Sustainability",
    domain: "NGO",
    job_description: "Key Responsibility Areas (KRAs): To plan and reach out to corporates, colleges, schools, and other bulk consumers in Mumbai: The interns will be responsible for identifying and contacting educational institutions, residential welfare associations, non-governmental organizations, and bulk consumers to promote our e-waste management initiatives.As an intern, you will: Plan and reach out to corporates, colleges, schools, and bulk consumers in Mumbai Deliver awareness sessions on e-waste management and its environmental impact Compile proofs of execution after the awareness program Conduct e-waste collection drives with local stakeholders Support the Karo Sambhav team in research and on-ground activities",
    mode: "Online",
    stipend: "10,000 / month",
    number_of_fellows: 2,
    website_link: "https://www.karosambhav.com/",
    city: "Mumbai",
    image: karosambahv,
  },
  {
    company_id: 69,
    company_name: "REVY Environmental Solutions Pvt. Ltd._Consult",
    job_title: "Customer Feedback Specialist Intern",
    domain: "Consult",
    job_description: "Collect and analyze customer feedback through surveys and other methods. Identify common themes and specific issues reported by customers. Work with the customer service team to address and resolve customer concerns. Create comprehensive feedback reports to help improve customer satisfaction.",
    mode: "Hybrid",
    stipend: "Pro-bono basis / month. Certificate provided upon completion.",
    number_of_fellows: 1,
    website_link: "https://www.revy.co.in/",
    city: "Vadodara",
    image: revy
  },
  {
    company_id: 44,
    company_name: "KIWI Kisan Window",
    job_title: "Organic Agriculture, Retail",
    domain: "Miscellaneous",
    job_description: "Organic Agriculture, Retail",
    mode: "Offline",
    stipend: "0 / month",
    number_of_fellows: 5,
    website_link: "https://kisanwindow.com/",
    city: "Dehradun, Uttarakhand",
    image: kisanwindow,
  },
  {
    company_id: 45,
    company_name: "LeapForWord",
    job_title: "Fullstack Developer",
    domain: "Web Dev",
    job_description: "Support the testing and development of LeapForWord's in-house system for Teacher Registration and Content Access. Engage in both frontend and backend development tasks. Technology Stack: Frontend: React, Laravel, CSS, JavaScript Backend: Laravel (PHP) Database: MySQL",
    required_skills: "Basic understanding of React and Laravel frameworks. Familiarity with PHP and MySQL. Knowledge of API development and integration. Database management and optimization tasks using MySQL.",
    mode: "Hybrid",
    stipend: "10-15k/month",
    number_of_fellows: "1-2",
    website_link: "https://leapforword.org/",
    city: "Goregaon, Mumbai",
    image: leapforword,
  },
  {
    company_id: 46,
    company_name: "Light of Life Trust",
    job_title: "Social Worker",
    domain: "NGO",
    job_description: "An organization which works with rural communities to bring back children to school and give them vocational training to get gainfully employed.",
    mode: "Offline",
    stipend: "Rs. 10000 / month",
    website_link: "www.lightoflifetrust.org",
    city: "Maharashtra - Raigad",
    image: lifetrust,
  },
  {
    company_id: 47,
    company_name: "MakeMySummary",
    job_title: "Software Developer Intern",
    domain: "AI/ML",
    job_description: "Software Development in Python/FastAPI and OpenaI. Condense recurring information in a nutshell.",
    mode: "Online",
    stipend: "INR 10000 / month",
    number_of_fellows: 2,
    website_link: "MakeMySummary",
    city: "Not Specified",
    image: makemy,
  },
 
  {
    company_id: 49,
    company_name: "Maker's Asylum_Marketing",
    job_title: "Marketing & Outreach Intern",
    domain: "Marketing",
    job_description: "Identify and contact potential partners and sponsors. Organize events and maintain relationships with community members. Participate in outreach events to promote Makers Asylum. Maintain databases and assist in preparing reports and presentations. Develop and execute social media strategies.",
    mode: "Offline",
    stipend: "15000 / month",
    number_of_fellows: 1,
    website_link: "https://makersasylum.com/",
    city: "Goa",
    image: maker,
  },
  {
    company_id: 52,
    company_name: "Medicaps",
    job_title: "UI Designer",
    domain: "Design",
    job_description: "The UI Designer will be responsible for creating user-friendly interfaces for the jewelry design project. This includes designing layouts, wireframes, and prototypes to enhance the customer experience. The designer will collaborate with the development team to ensure seamless integration of designs into the final product.",
    mode: "Online",
    stipend: "₹3000/month",
    number_of_fellows: 1,
    website_link: "www.medicaps.ac.in",
    city: "Indore",
    image: medicaps,
  },
  {
    company_id: 17,
    company_name: "Blockchained India_Media",
    job_title: "Blockchain and Web3",
    domain: "Media",
    job_description: "Founder's Staff - 1 Social Media - 1 Technical Writer - 1",
    mode: "Offline",
    stipend: "10,000 - 25,000 / month",
    number_of_fellows: 3,
    website_link: "https://www.blockchainedindia.com/",
    city: "Delhi",
    image: blockchain,
  },
  {
    company_id: 53,
    company_name: "MFARM VENTURES PRIVATE LIMITED",
    job_title: "Participation and Policy Design Architect",
    domain: "Miscellaneous",
    job_description: "A Participation and Policy Design Architect is an expert in crafting policies that actively involve stakeholders, ensuring inclusive decision-making processes for effective governance and societal impact. They specialize in designing frameworks that encourage collaboration, transparency, and responsiveness to diverse community needs and preferences.",
    mode: "Hybrid",
    stipend: "15000 / month",
    number_of_fellows: 4,
    website_link: "Not Specified",
    city: "Delhi",
    image: mfarm,
  },

  {
    company_id: 32,
    company_name: "Eshway_Web Dev",
    job_title: "Web Developer",
    domain: "Web Dev",
    job_description: "Develop and maintain responsive web applications, ensuring functionality and user experience.",
    mode: "Hybrid",
    stipend: "up to 25k INR / month",
    number_of_fellows: "1-2",
    website_link: "www.eshway.com",
    city: "Mumbai",
    image: eshway,
  },
  {
    company_id: 54,
    company_name: "Mindsprite Solutions",
    job_title: "Content Creator",
    domain: "Media",
    job_description: "Students will be required to curate the content for various competitive exams assigned.",
    mode: "Online",
    stipend: "Based on Project.",
    number_of_fellows: 10,
    website_link: "https://www.doorsteptutor.com/Exams/",
    city: "Not Specified",
    image: mindspirits,
  },
  {
    company_id: 55,
    company_name: "Uolwe",
    job_title: "Sahyogi",
    domain: "Miscellaneous",
    job_description: "Create and liaise for the biggest international org which will involve 8 billion people. It's not something like model UN, it's more practical to create an international org which can add and create more value. The person will be needed to work in several areas initially and remember this is not a conventional type of org.",
    mode: "Hybrid",
    stipend: "On outcome / month",
    number_of_fellows: 5,
    website_link: "https://kids.miraheze.org/wiki/Awe-Heal",
    city: "Multi locations",
    image: miraheze,
  },
  {
    company_id: 56,
    company_name: "Nexus Power",
    job_title: "Sustainability and Green Technologies",
    domain: "Technical",
    job_description: "Promoting sustainability and green technologies by building a bio-organic battery from crop residue.",
    mode: "Online",
    stipend: "Not Specified",
    number_of_fellows: "Not Specified",
    website_link: "https://nexuspower.in/",
    city: "Not Specified",
    image: nexus,
  },
  {
    company_id: 57,
    company_name: "NuSocia Pvt. Ltd.",
    job_title: "NTRC Fellow",
    domain: "Research project",
    job_description: "The Job will include a lot of primary research, analysis and presentation. We delve into a plethora of topics and the fellow will receive exposure to multiple approaches, methodologies, and thematic areas in the development world. The core job functions they will focus on are: Development of CSR database: this will include database development, CSR annual report data collection, and analysis of findings Industry Report development, data collection, and report writing Article/Content research and writing Data analysis of team performance indicators",
    mode: "Online",
    stipend: "12,000 INR / month",
    number_of_fellows: 1,
    website_link: "https://www.nusocia.com/",
    city: "Pune, Maharashtra",
    image: nusocio,
  },
  {
    company_id: 58,
    company_name: "FOUNDATION FOR PARTICIPATORY DEVELOPMENT COMMUNICATION AND RESEARCH",
    job_title: "Research and Communication Intern",
    domain: "Research Project",
    job_description: "Qualitative and Quantitative Research - Both online secondary research and on-ground primary including community mapping, surveys, focus group discussions, etc. Communication and Outreach - stakeholders such as community, other organizations, educational institutions, and government bodies, etc. and online outreach. Additionally, the interns will be required to contribute to program development and implementation process as and whenever required.",
    mode: "Hybrid",
    stipend: "We are unable to provide any stipend or incentives at this point",
    number_of_fellows: 4,
    website_link: "Not Specified",
    city: "Mumbai / navi Mumbai",
    image: pdcr,
  },
  {
    company_id: 59,
    company_name: "Pikk",
    job_title: "Marketing - Sales - Product Market Fit",
    domain: "Marketing",
    job_description: "Startup",
    mode: "Hybrid",
    stipend: "5000-10000 / month",
    number_of_fellows: "2-3",
    website_link: "Not Specified",
    city: "Pune",
    image: pikk,
  },
  {
    company_id: 60,
    company_name: "Pranyas Development Foundation_design",
    job_title: "Graphic Designing",
    domain: "Design",
    job_description: "1. Graphic Designing - Live Pan India Project poster designing, Important dates posters, Campaign posters, etc 2. Motion Graphic Designing - Animated video for kids and company website",
    mode: "Online",
    stipend: "Graphic Designing - 10000 INR per month Motion Graphic Designing - 10000 INR per month",
    number_of_fellows: "Graphic Designing - 4 Motion Graphic Designing - 1",
    website_link: "www.pranyas.org",
    city: "NA",
    image: pranyas,
  },
  {
    company_id: 61,
    company_name: "Pranyas Development Foundation_marketing",
    job_title: "Digital Marketing",
    domain: "Marketing",
    job_description: "Digital Marketing - Branding, Social media handle, SEO & SMO, Collaboration with colleges on live pan India projects, etc Content Writing - Blog, Social media content, Microblog, Story writing, campaign content, etc",
    mode: "Online",
    stipend: "Digital Marketing - 5000 INR per month Content Writing - 5000 INR per month",
    number_of_fellows: "Digital Marketing - 1 Content Writing - 1",
    website_link: "www.pranyas.org",
    city: "NA",
    image: pranyas
  },
  {
    company_id: 62,
    company_name: "Pride+ App",
    job_title: "Associate (Marketing, Partnerships, Community Ambassador)",
    domain: "Marketing",
    job_description: "Develop and execute innovative social media strategies. Create engaging content and manage various social platforms. Build and maintain partnerships aligned with our mission. Engage actively with online and offline communities.",
    mode: "Online",
    stipend: "Unpaid for 30 days. Based on performance, full-time with benefits opportunity for a permanent position. / month. :4 weeks",
    number_of_fellows: 3,
    website_link: "www.pranahsandbox.com",
    city: "Not Specified",
    image: pride
  },
  {
    company_id: 63,
    company_name: "Prithak Foundation",
    job_title: "PF ChangeMakers",
    domain: "NGO",
    job_description: "Yet to launch",
    mode: "Hybrid",
    stipend: "1-5K / month",
    number_of_fellows: 10,
    website_link: "www.prithakfoundation.org",
    city: "Delhi",
    image: prithak,
  },
  {
    company_id: 64,
    company_name: "Purnata",
    job_title: "Versatile (Depends on skills)",
    domain: "NGO",
    job_description: "Prepare job profile based on the skill and interest of the applicant.",
    mode: "Hybrid",
    stipend: "Paid internships available",
    number_of_fellows: "Up to 5",
    website_link: "www.purnata.org",
    city: "Mumbai and Kolkata",
    image: purnata
  },
  {
    company_id: 65,
    company_name: "Raatai Handloom",
    job_title: "Product Development Team Member",
    domain: "Product Management",
    job_description: "Collaborate with the design team to develop innovative designs for handloom products. Work on product development from concept to production, ensuring quality and functionality. Utilize CAD software and technical skills to create detailed product designs. Contribute to the improvement and optimization of existing products.",
    mode: "Hybrid (Online and On-site)",
    stipend: "Based on skills",
    number_of_fellows: 5,
    website_link: "www.raatai.com",
    city: "Not specified",
    image: raatai
  },
  {
    company_id: 66,
    company_name: "Raudic Placement's Pvt Ltd",
    job_title: "Recycling and Sustainability",
    domain: "NGO",
    job_description: "Transforming waste materials like plastic and rubber into functional products to promote sustainability and waste reduction in India.",
    mode: "Online",
    stipend: "0-4k / month",
    number_of_fellows: 4,
    website_link: "Not Specified",
    city: "Not Specified",
    image: raudic
  },
  {
    company_id: 67,
    company_name: "REVY Environmental Solutions Pvt. Ltd._Analyst",
    job_title: "Customer Experience Analyst Intern",
    domain: "Analyst",
    job_description: "Conduct customer surveys to gather feedback on REVY's products and services. Analyze data to identify trends and areas for improvement in customer satisfaction. Collaborate with the marketing team to enhance the overall customer experience. Prepare detailed reports and present findings to senior management.",
    mode: "Hybrid",
    stipend: "Pro-bono basis / month. Certificate provided upon completion.",
    number_of_fellows: 1,
    website_link: "https://www.revy.co.in/",
    city: "Vadodara",
    image: revy
  },


  {
    company_id: 10,
    company_name: "Atya Education",
    job_title: "Graphic designers",
    domain: "Design",
    job_description: "Create visual content for digital and print media.",
    mode: "Online",
    stipend: "2000-15000 / month",
    number_of_fellows: 5,
    website_link: "https://www.atyaeducation.com/",
    city: "NA",
    image: atya,
  },




  {
    company_id: 35,
    company_name: "Eshway",
    job_title: "Sales",
    domain: "Marketing",
    job_description: "Drive sales by identifying potential clients, presenting services, and closing deals.",
    mode: "Hybrid",
    stipend: "up to 25k INR / month",
    number_of_fellows: "1-2",
    website_link: "www.eshway.com",
    city: "Mumbai",
    image: eshway,
  },
  {
    company_id: 72,
    company_name: "Rina Shiksha Sahayak Foundation",
    job_title: "Teaching",
    domain: "NGO",
    job_description: "The Intern Faculty position offers a valuable opportunity to gain hands-on experience in teaching. You'll support classroom instruction by developing engaging activities for students. Additionally, you'll assist with administering tests and evaluations, providing valuable insights into student progress. Collaboration is key, so you'll participate in faculty meetings and professional development opportunities to learn from experienced educators. Finally, you'll be a supportive resource for students, helping them troubleshoot academic challenges and ensure their success.",
    mode: "Offline",
    stipend: "Not Specified",
    number_of_fellows: 8,
    website_link: "https://www.rssi.in/",
    city: "Lucknow",
    image: rina
},

{
  company_id: 9,
  company_name: "Atya Education",
  job_title: "Marketing",
  domain: "Marketing",
  job_description: "Support marketing tasks to promote brands.",
  mode: "Online",
  stipend: "2000-15000 / month",
  number_of_fellows: 5,
  website_link: "https://www.atyaeducation.com/",
  city: "NA",
  image: atya,
},

{
    company_id: 73,
    company_name: "Society for Environment & Sustainable Development",
    job_title: "Multiple Positions",
    domain: "Marketing",
    job_description: "Setting up an e-commerce market place for sale of rural products, making us visible to companies who do csr and government and arrange for funds and donations, content writing to glorify our work, Graphic representation of our work, Digital Marketing, Photography of our work, personality development of our staff and educators so that they can download this to the beneficiaries.",
    mode: "Hybrid",
    stipend: "NA",
    number_of_fellows: null,
    website_link: "www.sensed.org.in",
    city: "Obedullaganj Block of Raisen District in MP, 35 km from state capital Bhopal.",
    image: sensed
},

{
    company_id: 74,
    company_name: "Seva Sahayog Foundation",
    job_title: "Developing project tracking tools.",
    domain: "Analyst",
    job_description: "Field visits for need assessment, impact assessment of various projects, data analysis, and developing project tracking tools.",
    mode: "Hybrid",
    stipend: "Nil/month",
    number_of_fellows: 2,
    website_link: "www.sevasahayog.org",
    city: "Mumbai, Thane, Palghar, and Raigad districts",
    image: sevasahayog
},





{
    company_id: 77,
    company_name: "Shandar services pvt Ltd_Web Dev",
    job_title: "IT",
    domain: "Web Dev",
    job_description: "Development: Assist in software development and debugging. Support: Provide technical assistance. Database: Manage and optimize databases. Integration: Integrate new software. Documentation: Prepare technical documents. Innovation: Contribute ideas for tech solutions.",
    mode: "Hybrid",
    stipend: "2500 to 5000/month",
    number_of_fellows: 10,
    website_link: "www.shandarservices.com",
    city: "Patna Bihar",
    image: shandar
},

{
    company_id: 78,
    company_name: "Sheel Educational & Welfare Society",
    job_title: "Multiple Positions",
    domain: "NGO",
    job_description: "An organisation which works with Rural communities to bring back children to school and give them vocational training to get gainfully employed",
    mode: "Offline",
    stipend: "Yes",
    number_of_fellows: null,
    website_link: "www.seswngo.org",
    city: "Not Specified",
    image: sheel
},

{
    company_id: 79,
   company_name: "Shiksharth Trust",
    job_title: "Google Data Studio System Creation Intern",
    domain: "Analyst",
    job_description: "Designing and implementing a Google Data Studio system to analyze various data sources related to classroom instruction, community engagement, and systemic support. Integrating data from different sources to create comprehensive reports and dashboards that provide insights into program effectiveness and areas for improvement. Training team members on how to use and interpret the data studio system effectively.",
    mode: "Online",
    stipend: "₹7000/month",
    number_of_fellows: 2,
    website_link: "https://shiksharth.in/",
    city: "Sukma, Chhattisgarh",
    image: shikshasharth
},
{
  company_id: 80,
  company_name: "SOS Children's Villages of India",
  job_title: "Data Collection Systems",
  domain: "Analyst",
  job_description: "We collect lots of data from our field operations. However, we use only desktops and laptops to do this work. This reduces the focus on the field as well as causes avoidable delays. We intend to develop data flow diagrams and smarter handheld systems that can help us collect data seamlessly in real-time and help us analyze the data.",
  mode: "Offline",
  stipend: "Will get back to you on this shortly",
  number_of_fellows: 3,
  website_link: "SOS Children's Villages of India",
  city: "Begusarai (Bihar), Faridabad (Haryana)",
  image: sos
},
{
  company_id: 48,
  company_name: "Maker's Asylum_Research Project",
  job_title: "Education Researcher / Product design",
  domain: "Research Project",
  job_description: "Content development and educational kit designs to show the path to more learners to become Makers. Education and Product Design Join a creative Maker community and work on content development and educational kit designs to show the path to more learners to become Makers",
  mode: "Offline",
  stipend: "15 to 20k / month",
  number_of_fellows: 3,
  website_link: "https://makersasylum.com/",
  city: "North Goa",
  image: maker,
},

{
  company_id: 81,
  company_name: "SwitchON Foundation",
  job_title: "Strategy Marketing",
  domain: "Marketing",
  job_description: "Just Transition to Future - Energy Technology Challenge. Design the program, plan its outreach/marketing strategy, and execute recommended strategies.",
  mode: "Hybrid",
  stipend: "As per market rates/month",
  number_of_fellows: 1,
  website_link: "https://www.switchon.org.in/",
  city: "Kolkata",
  image: switchon
},

{
  company_id: 82,
  company_name: "TAANGH",
  job_title: "Research Project",
  domain: "Research Project",
  job_description: "As a TAANGH intern, you'll play a pivotal role in our research and development. This internship offers a unique opportunity to gain hands-on experience in the exciting field of upcycled materials for interior furnishings. Working in a founding-stage startup presents a challenging and rewarding opportunity for you to thrive and make a real impact.",
  mode: "Offline",
  stipend: "8-12,000/month",
  number_of_fellows: 1,
  website_link: "www.taangh.com",
  city: "Roopnagar, Punjab",
  image: taangh
},

{
  company_id: 83,
  company_name: "ThinkZone",
  job_title: "Machine Learning",
  domain: "AI/ML",
  job_description: "The fellows will be working on the following project. Enhancing Accuracy, Efficiency, and Personalization: Working with the ThinkZone team in developing an initial version of a Vernacular Language-based Model for correct identification of foundational skills of primary-grade students at scale.",
  mode: "Online",
  stipend: "INR 15000/month",
  number_of_fellows: null,
  website_link: "https://www.thinkzon1.1e.in",
  city: "NA",
  image: thinkzone
},

{
  company_id: 84,
  company_name: "Tickle Your Art",
  job_title: "Sales, Marketing, Social Media",
  domain: "Marketing",
  job_description: "Develop and execute sales strategies to drive growth for the brand. Identify potential customers and generate leads through various channels. Build and maintain relationships with clients to ensure customer satisfaction and retention. Collaborate with the marketing team to create promotional materials and campaigns.",
  mode: "Offline",
  stipend: "Depends on the experience",
  number_of_fellows: 1,
  website_link: "www.tickleyourart.com",
  city: "Pune",
  image: tickelforart
},

{
  company_id: 85,
  company_name: "Toptrove Foundation",
  job_title: "HR, SMM, Volunteers, Social Work, Graphic Design, Content Writer, Tech",
  domain: "NGO",
  job_description: "The foundation aims to improve the welfare of individuals globally, providing educational resources, essential skills, and promoting a healthy lifestyle.",
  mode: "Online",
  stipend: "Not Specified",
  number_of_fellows: 10,
  website_link: "https://toptrove.org/",
  city: "Bangalore",
  image: toptrove
},

{
  company_id: 68,
  company_name: "REVY Environmental Solutions Pvt. Ltd.Marketing",
  job_title: "Market Research Intern",
  domain: "Marketing",
  job_description: "Assist in designing and distributing customer surveys to gather market insights. Compile and analyze survey data to support strategic business decisions. Track market trends and competitors to provide actionable recommendations. Contribute to the development of new products and marketing strategies based on research findings.",
  mode: "Hybrid",
  stipend: "Pro-bono basis / month. Certificate provided upon completion.",
  number_of_fellows: 1,
  website_link: "https://www.revy.co.in/",
  city: "Vadodara",
  image: revy
},

{
  company_id: 86,
  company_name: "TruCup",
  job_title: "Sustainability, Women's Health",
  domain: "Miscellaneous",
  job_description: "This internship offers a unique opportunity to gain hands-on experience in various aspects of our organization, including conducting workshops, fundraising, and managing social media. The ideal candidate is passionate about sustainability and women's health, eager to learn, and ready to contribute to our mission.",
  mode: "Online",
  stipend: "3000/month",
  number_of_fellows: 1,
  website_link: "www.trucup.co",
  city:"Bangalore",
  image: trucup
},

{
  company_id: 87,
  company_name: "Tummas",
  job_title: "Social Innovation Interns",
  domain: "NGO",
  job_description: "Project on child malnutrition, TB, and stress management.",
  mode: "Online",
  stipend: "na",
  number_of_fellows: 2,
  website_link: "www.tumaasfoundation.ngo",
  city: "NA",
  image: tummas
},

{
  company_id: 88,
  company_name: "Vision Search",
  job_title: "Coordinator",
  domain: "Media",
  job_description: "1. Impact analysis of Familymaker Conference & Awards 2. Making Report of the event or panel discussions 3. Making reels out of event and panel discussion videos 4. Drafting press release of the event. 5. Marketing and follow-up",
  mode: "Online",
  stipend: "Not Specified",
  number_of_fellows: 2,
  website_link: "www.monamehra.com",
  city: "Not Specified",
  image: visions
},

{
  company_id: 89,
  company_name: "UNICORNIZ INNOVATION",
  job_title: "VR Development",
  domain: "Design",
  job_description: "Interns at VRKSHETRA will engage in developing and creating VR content for tourism and education, assist in technical development and UX design, conduct research, and support marketing and partnership initiatives, gaining hands-on experience in VR technology.",
  mode: "Hybrid",
  stipend: "11-22k",
  number_of_fellows: 5,
  website_link: "https://unicorniz.com/",
  city: "NA",
  image: vrkshetra
},

{
  company_id: 90,
  company_name: "WASTE CONNECT",
  job_title: "Waste Warriors",
  domain: "NGO",
  job_description: "Conduct awareness programs/campaigns on waste segregation at source at homes.",
  mode: "Offline",
  stipend: "10,000/month",
  number_of_fellows: 2,
  website_link: "Www.wasteconnect.org",
  city: "Visakhapatnam, Andhra Pradesh.",
  image: waste
},

{
  company_id: 91,
  company_name: "WNE3 Technologies Pvt Ltd",
  job_title: "Technology, eCommerce",
  domain: "Web Dev",
  job_description: "We're looking to welcome passionate technology enthusiasts and innovators to our team, playing a pivotal role in bringing our vision to life. Our current openings encompass various opportunities for Django Developers, UI/UX Designers, Community Builders, Content Writers, and Social Media Curators. Whether you're keen on shaping engaging content, designing seamless user experiences, fostering a vibrant community, or curating an impactful social media presence, we have a place for you. Your specific role will align with your area of interest, be it generative AI, creative content, design, or social media strategies. Even if your expertise doesn't fit precisely into these categories but you're intrigued by our mission, we wholeheartedly encourage you to apply.",
  mode: "Online",
  stipend: "0/month",
  number_of_fellows: 10,
  website_link: "www.wne3.live",
  city: "Visakhapatnam, Andhra Pradesh",
  image: wne3
},

{
  company_id: 92,
  company_name: "World Vision India",
  job_title: "Fundraising Executive - Sponsorship Consultant",
  domain: "Consult",
  job_description: "The role involves initiating, implementing, and leading fundraising campaigns and donation drives across India. The candidate will be responsible for identifying and communicating with prospective donors, organizing sponsor campaigns, and managing marketing activities to effectively promote the organization's goals in child and social welfare.",
  mode: "Hybrid",
  stipend: "Open for discussion",
  number_of_fellows: 20,
  website_link: "https://www.worldvision.in/index.aspx",
  city: "PAN India",
  image: worldv
},

{
  company_id: 93,
  company_name: "Bupp Technologies Pvt Ltd_Web Dev",
  job_title: "Android app developer (preferably full stack)",
  domain: "Web Dev",
  job_description: "1) APP DEVELOPER: Strategize, design and develop a basic app that records honking and uses the data to gamify reduction in honking. Knowledge of basic animation a plus.",
  mode: "Hybrid",
  stipend: "₹50000",
  number_of_fellows: 1,
  website_link: "Bupp Technologies Pvt Ltd",
  city: "Mumbai",
  image: bupp
},

{
  company_id: 94,
  company_name: "Bupp Technologies Pvt Ltd_analtics",
  job_title: "Grant locator and applier",
  domain: "Miscellaneous",
  job_description: "GRANT LOCATOR AND APPLIER: Locate government, private, or any other national or international grant applicable for this cause and apply for the grant.",
  mode: "Hybrid",
  stipend: "₹20000",
  number_of_fellows: 1,
  website_link: "Bupp Technologies Pvt Ltd",
  city: "Mumbai",
  image: bupp
},

{
  company_id: 95,
  company_name: "Your Digital Boat",
  job_title: "Business Development and Marketing Interns",
  domain: "Marketing",
  job_description: "Join Your Digital Boat, a dynamic 360 Digital Marketing agency focused on the education sector. We are looking for an experienced Business Development and Marketing Manager to expand our client base and enhance our market presence.",
  mode: "Hybrid",
  stipend: "8000-15000/month",
  number_of_fellows: 2,
  website_link: "https://yourdigitalboat.com/",
  city: "Delhi",
  image: yourdigital
},
{
  company_id: 71,
  company_name: "REVY Environmental Solutions Pvt. Ltd._analtics",
  job_title: "Customer Relations Intern",
  domain: "Consult",
  job_description: "Engage with customers to collect feedback through surveys and direct interactions. Monitor and document customer satisfaction levels and pain points. Assist in developing strategies to enhance customer relationships and loyalty. Provide regular updates to the management team on customer insights and trends.",
  mode: "Hybrid",
  stipend: "Pro-bono basis / month. Certificate provided upon completion.",
  number_of_fellows: 1,
  website_link: "https://www.revy.co.in/",
  city: "Vadodara",
  image: revy
},

{
  company_id: 76,
  company_name: "Shandar services pvt Ltd_Marketing",
  job_title: "Marketing & IT",
  domain: "Marketing",
  job_description: "Market Research: Analyze trends and customer behavior. Campaigns: Design and implement marketing strategies. Content: Develop blogs, newsletters, and visuals. SEO: Optimize website content. Data Analysis: Measure campaign effectiveness and recommend improvements.",
  mode: "Hybrid",
  stipend: "2500 to 5000/month",
  number_of_fellows: 10,
  website_link: "www.shandarservices.com",
  city: "Patna Bihar",
  image: shandar
},




]


export default company_list;
